import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Carousel } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Concrete = () => (
  <Layout>
    <SEO
      title="Asphalt"
      description="From excavation and preparation through to final surfacing, we can
      manage your asphalt project from start to finish."
      image={"/asphalt.jpg"}
    />

    <div className="row g-0">
      <div className="col-lg-6 d-flex align-items-center">
        <div className="content-box">
          <h1 className="section-heading section-heading--left">Asphalt</h1>
          <p>
            From excavation and preparation through to final surfacing, we can
            manage your asphalt project from start to finish.
          </p>
          <p> We offer a wide range of asphalt services:</p>
          <ul>
            <li>Driveways</li>
            <li>Car Parks</li>
            <li>Sports Areas</li>
            <li>Road Construction</li>
            <li>Reinstatement</li>
          </ul>
          <p>
            <Link className="btn btn-primary mt-4" to="/contact/">
              Free quote
            </Link>
          </p>
        </div>
      </div>
      <div className="col-lg-6 d-flex justify-content-center justify-content-lg-end">
        {/* <Carousel>
              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  src="../images/concrete.jpg"
                  alt="concrete path"
                  loading="eager"
                />
              </Carousel.Item>
            </Carousel> */}
        <StaticImage
          aspectRatio={16 / 11}
          src="../images/asphalt.jpg"
          alt="Asphalt subdivision"
          loading="eager"
        />
      </div>
    </div>
  </Layout>
);

export default Concrete;
